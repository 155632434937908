import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../services/instance";
import { handleAsyncError } from "../utils/Helper";
import { message } from "antd";

const initialState = {
  // end Of Days Statements
  EODStatements: null,
  EODStatementsLoading: false,
  // end Of Days single Statement
  EODSingleStatement: null,
  EODSingleStatementLoading: false,
  // account balance
  accountBalance: null,
  accountBalanceLoading: false,
  // account statement
  accountStatement: null,
  accountStatementLoading: false,

  // export statements csv
  statementsCSVLoading: false,
};

//^ ========= get EOD statements ============
export const getEODStatements = createAsyncThunk("fundsManagement/getEODStatements", async (_, { rejectWithValue }) => {
  try {
    return await axiosInstance
      .get(`/admin/anb/getEndOfDaysStatements`)
      .then((response) => {
        return response?.data?.data;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    throw e;
  }
});
//^ ========= get EOD single statement ============
export const getEODSingleStatement = createAsyncThunk(
  "fundsManagement/getEODStatement",
  async (id, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`/admin/anb/getEndOfDaysStatement/${id}`)
        .then((response) => {
          return response?.data?.data;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      throw e;
    }
  }
);
//^ ========= get account balance ============
export const getAccountBalance = createAsyncThunk(
  "fundsManagement/getAccountBalance",
  async (_, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`/admin/anb/getAccountBalance`)
        .then((response) => {
          return response?.data?.data;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= get account statements ============
export const getAccountStatement = createAsyncThunk(
  "fundsManagement/getAccountStatement",
  async (params, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`/admin/anb/getAccountStatement`, { params })
        .then((response) => {
          return response?.data?.data;
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= get account statements ============
export const getANBCashInAccountStatement = createAsyncThunk(
  "fundsManagement/getANBCashInAccountStatement",
  async (params, { rejectWithValue }) => {
    try {
      return await axiosInstance
        .get(`/admin/anb/getANBCashInAccountStatement`, { params })
        .then((response) => {
          message.success("Resonsile Successfully");
          return response?.data?.data;
        })
        .catch((error) => {
          message.error("Resonsile UnSuccessful");

          console.log(error);
        });
    } catch (e) {
      throw e;
    }
  }
);

//^ ========= get statements csv  ============ //
export const getStatementsCSV = createAsyncThunk("fundsManagement/getStatementsCSV", async () => {
  try {
    return await axiosInstance
      .get(`admin/anb/exportAccountStatement`)
      .then((response) => {
        console.log(response, "getInvestorsCSV");
        const link = document.createElement("a");
        link.href = response?.data?.data?.fileUrl;
        link.download = "investors.pdf";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        return response?.data?.data?.fileUrl;
      })
      .catch((error) => {
        console.log(error);
      });
  } catch (e) {
    throw e;
  }
});

//^ ========= withdraw (from admin to external accounts) ============ //
// 1st step send withdrawal otps 
export const postSendWithdrawlOtps = createAsyncThunk(
  "fundsManagement/postSendWithdrawlOtps",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const response = await axiosInstance.post(`/payment/sent-withdrawal-opts`, data);
      if (response) {
        message.success(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      return handleAsyncError(error, rejectWithValue);
    }
  }
);

// 2nd step: confirm withdraw
export const postPaymentWithdraw = createAsyncThunk(
  "fundsManagement/postAddBankAccountSecondStep",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      axiosInstance.defaults.headers.post["Content-Type"] = "multipart/form-data";
      const response = await axiosInstance.post(`/payment/withdraw`, data);
      if (response) {
        dispatch(getAccountBalance());
        message.success(response?.data?.message);
        return response?.data;
      }
    } catch (error) {
      return handleAsyncError(error, rejectWithValue);
    }
  });

/*-----------------------Slice----------------*/
const fundsManagement = createSlice({
  name: "fundsManagementSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // EOD statements
    builder.addCase(getEODStatements.pending, (state) => {
      state.EODStatementsLoading = true;
    });
    builder.addCase(getEODStatements.fulfilled, (state, action) => {
      state.EODStatementsLoading = false;
      state.EODStatements = action.payload;
    });
    builder.addCase(getEODStatements.rejected, (state) => {
      state.EODStatementsLoading = false;
    });

    // EOD single statement
    builder.addCase(getEODSingleStatement.pending, (state) => {
      state.EODSingleStatementLoading = true;
    });
    builder.addCase(getEODSingleStatement.fulfilled, (state, action) => {
      state.EODSingleStatementLoading = false;
      state.EODSingleStatement = action.payload;
    });
    builder.addCase(getEODSingleStatement.rejected, (state) => {
      state.EODSingleStatementLoading = false;
    });

    // account balance
    builder.addCase(getAccountBalance.pending, (state) => {
      state.accountBalanceLoading = true;
    });
    builder.addCase(getAccountBalance.fulfilled, (state, action) => {
      state.accountBalanceLoading = false;
      state.accountBalance = action.payload;
    });
    builder.addCase(getAccountBalance.rejected, (state) => {
      state.accountBalanceLoading = false;
    });

    // account statements
    builder.addCase(getAccountStatement.pending, (state) => {
      state.accountStatementLoading = true;
    });
    builder.addCase(getAccountStatement.fulfilled, (state, action) => {
      state.accountStatementLoading = false;
      state.accountStatement = action.payload;
    });
    builder.addCase(getAccountStatement.rejected, (state) => {
      state.accountStatementLoading = false;
    });

    // withdraw
    builder.addCase(postSendWithdrawlOtps.pending, (state) => {
      state.withdrawOtpsLoading = true;
    });
    builder.addCase(postSendWithdrawlOtps.fulfilled, (state, action) => {
      state.withdrawOtpsLoading = false;
    });
    builder.addCase(postSendWithdrawlOtps.rejected, (state) => {
      state.withdrawOtpsLoading = false;
    });


    builder.addCase(postPaymentWithdraw.pending, (state) => {
      state.withdrawLOading = true;
    });
    builder.addCase(postPaymentWithdraw.fulfilled, (state, action) => {
      state.withdrawLOading = false;
    });
    builder.addCase(postPaymentWithdraw.rejected, (state) => {
      state.withdrawLOading = false;
    });

  },
});

export default fundsManagement.reducer;
